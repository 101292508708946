export const design = {
    "title": "Design & Créatif",
    "titleText": "Nous créons des concepts visuels percutants et adaptés à votre marque, soutenus par des données et un soupçon de psychologie comportementale.<br><br>Allant de logos captivants aux interfaces web attrayantes et aux documents imprimés, notre expertise créative s'étend sur un large éventail de plateformes et de formats.",
    "text1": "Nous analysons le comportement de vos utilisateurs et <span>intégrons ces informations</span> dans notre processus de conception pour créer des visuels convaincants qui <span>favorisent l'engagement</span>, les conversions et la fidélité de vos clients.",
    "text2": "Laissez-nous vous aider à perfectionner vos campagnes numériques, <span>améliorer votre présence en ligne</span> et créer des liens durables avec votre audience.",
    "bullet1": "<span class='big text-5xl bold block'>300+</span>sites web créés",
    "bullet2": "<span class='big text-5xl bold block'>500+</span>designs imprimés",
    "bullet3": "<span class='big text-5xl bold block'>15 ans</span>d'expérience",
    "bullet4": "<span class='big text-5xl bold block'>12</span>développeurs <br> et designers",
}
