export const interfaces = {
    "title1": "Interfaces d'administration",
    "text1": "Nous créons des plateformes intranet adaptées à vos besoins spécifiques qui sont à la fois conviviales et sécurisées.<br/><br/>Notre objectif principal est de garantir que vos employés aient accès aux bonnes ressources et outils pour stimuler la productivité et la collaboration tout en améliorant l'efficacité des processus de travail au sein de votre organisation.",
    "title2": "Nous développons des systèmes sécurisés et faciles à naviguer qui optimisent vos processus, <span>gèrent vos données sensibles et simplifient vos communications</span> entre les parties prenantes.",
    "title3": "Laissez-nous vous aider à <span>libérer le plein potentiel</span> de votre organisation et des ses capacités de collaboration.",
    "bullet1": "<span class='big text-5xl bold block'>300+</span>sites web créés",
    "bullet2": "<span class='big text-5xl bold block'>150+</span>clients satisfaits",
    "bullet3": "<span class='big text-5xl bold block'>15 ans</span>d'expérience",
    "bullet4": "<span class='big text-5xl bold block'>12</span>développeurs <br> et designers",
}
