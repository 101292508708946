export const cloudhosting = {
    "title1": "Infrastructure cloud (CI/CD)",
    "text1": "Nous travaillons avec divers fournisseurs et plateformes cloud tels que Amazon Web Services (AWS), Microsoft Azure et Google Cloud Platform (GCP), pour fournir la solution la plus adaptée à votre organisation.<br><br>Notre engagement en matière de sécurité et de conformité garantit que vos données restent protégées et respectent les normes et réglementations du secteur.",
    "button": "Contactez-nous",
    "title2": "Adopter les technologies cloud permet à votre organisation d'accéder à des ressources informatiques sur demande, <span>de s'adapter rapidement afin de supporter votre croissance</span> et de réduire considérablement vos coûts informatiques.",
    "title3": "En choisissant nos services cloud, vous investirez dans un environnement informatique à l'épreuve du temps qui <span>améliore non seulement l'efficacité et l'agilité</span>, mais qui favorise également la croissance et le succès à long terme.",
    "bullet1": "<span class='big text-5xl bold block'>SOC2</span>conforme",
    "bullet2": "<span class='big text-5xl bold block'>ISO 27001</span>conforme",
    "bullet3": "<span class='big text-5xl bold block'>PCI</span>conforme",
    "bullet4": "<span class='big text-5xl bold block'>15 ans</span>d'expérience",
}