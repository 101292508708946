export const vrar = {
    "title1": "Expériences VR/AR",
    "text1": "La réalité virtuelle (VR) et la réalité augmentée (AR) ont révolutionné la façon dont nous expérimentons le monde qui nous entoure. <br><br>Les expériences VR/AR permettent à votre entreprise de créer des environnements immersifs et captivants qui encouragent vos clients à interagir avec vos produits ou services d'une manière totalement inédite.",
    "title2": "Des visites virtuelles 360 degrés aux démonstrations de produits interactives,<span> nous utilisons les dernières technologies et techniques de conception</span> pour créer des expériences immersives pour vos clients.",
    "title3": "Nos expériences VR/AR <span>peuvent être déployées dans divers contextes</span>, tels que les salons professionnels, les conférences, les magasins à rayons et les plateformes numériques.",
    "bullet1": "<span class='big text-5xl bold block'>5+</span>environnements VR créés",
    "bullet2": "<span class='big text-5xl bold block'>15+</span>camapagnes AR créées",
    "bullet3": "<span class='big text-5xl bold block'>15 ans</span>d'expérience",
    "bullet4": "<span class='big text-5xl bold block'>12</span>développeurs <br> et designers",
}
