export const customweb = {
    "slideTitle": "Développement web personnalisé",
    "slideText": "Nous proposons des solutions haut de gamme pour les entreprises et les organisations ayant des besoins très spécifiques qui dépassent les limites des plateformes conventionnelles.<br/><br/>Nous comprenons que chaque entreprise est unique et que, pour réussir dans la situation économique concurrentielle d'aujourd'hui, vous avez besoin d'une présence en ligne qui reflète votre identité de marque et répond à vos exigences spécifiques.",
    "contactUs": "Contactez-nous",
    "craftDigitalExperiences": "Nous créons des expériences numériques qui non seulement <span>s'alignent avec les valeurs et l'esthétique de votre marque</span>, mais qui offrent également une expérience utilisateur <span>simple et efficace</span>.",
    "enterpriseFrameworks": "Nous travaillons exclusivement avec des outils <span>robustes et reconnus</span> afin de vous garantir une performance et fiabilité exceptionnelle.",
    "bullet1": "<span class='big text-5xl bold block'>300+</span>sites web créés",
    "bullet2": "<span class='big text-5xl bold block'>150+</span>clients satisfaits",
    "bullet3": "<span class='big text-5xl bold block'>15 ans</span>d'expérience",
    "bullet4": "<span class='big text-5xl bold block'>12</span>développeurs <br> et designers",
}
