export const about = {
    "title": "Tout a commencé il y a 15 ans dans un petit appartement à",
    "location": "Montréal",
    "header": "Nous sommes maintenant une équipe <span>multidisciplinaire</span> de développeurs, de designers et de créateurs de contenu.",
    "ceo": "Président",
    "leadDeveloper": "Développeur principal",
    "directorOperations": "Directrice des opérations",
    "directorSales": "Directeur des ventes",
    "projectManager": "Gestionnaire de projet",
    'teamLead': "Gestionnaire d'équipe",
    "webDeveloper": "Développeur web",
    "seniorDeveloper": "Développeur web sénior",
    "frontDeveloper": "Développeur Front-End",
    "illustratorDesigner": "Illustrateur & Designer",
    "graphicDesigner": "Designer graphique",
    "contentStrategist": "Vice-présidente, Associée",
    "translator": "Traductrice",
    "networkingIT": "Gestionnaire TI/Réseau",
    "whyWorkWithRollin": "Pourquoi nous choisir?",
    "whyText": "<b>Nous sommes une équipe agile d'experts développeurs.</b><br/><br/> Nous possédons les connaissances et l'expérience nécessaires pour aborder des projets complexes et fournir des solutions sur mesure pour nos clients.",
    "weAreCloseKnitted": "Nous sommes une équipe soudée et nous offrons une rémunération compétitive, des semaines de travail de 4 jours et des congés illimités.",
    "thinkGreatFit": "Si vous pensez être un excellent candidat, consultez nos offres d'emploi.",
    "seeWhyDifferent": "Découvrez en quoi nous sommes différents",
    "lookingForGreatPlace": "Vous cherchez un excellent lieu de travail ?",
    "alwaysOnLookout": "Nous sommes toujours à la recherche de talents exceptionnels !",
    "viewJobOpenings": "Voir nos offres d'emploi",
    "lookingForResources": "Vous cherchez des ressources techniques ?",
    "understandTechnicalChoices": "Si vous cherchez à mieux comprendre les choix techniques derrière les technologies que nous utilisons, consultez notre page de ressources pour en savoir plus sur le design graphique, les options de CMS et le web en général.",
    "checkResources": "Consultez nos ressources",
    "highly_specialized": "Nous sommes une petite équipe de développeurs <span>hautement spécialisés.</span>",
    "nimble_team": "Nous sommes une équipe agile d'experts développeurs. Nous possédons les connaissances et l'expérience nécessaire pour aborder des projets complexes et fournir des solutions sur mesure et de haute qualité pour nos clients.",
    "famous_brands": "Nous collaborons avec des marques célèbres pour créer des <span>expériences uniques.</span>",
    "worked_with_brands": "Nous combinons notre expertise avec les visions uniques de nos partenaires pour produire des expériences mémorables qui ravissent nos clients et leur public. Nous avons travaillé avec certaines des plus grandes marques du monde telles que Diesel, Nike et Twitter.",
    "well_renowned_tools": "Nous utilisons des <span>outils bien renommés</span> qui sont conçus pour garantir votre succès.",
    "harness_power": "Nous nous fions à la puissance de technologies éprouvées et réputées pour fournir des solutions évolutives et performantes qui correspondent à vos exigences et à vos objectifs spécifiques.",
    "quick_effective_available": "Notre équipe est <span>rapide, efficace et disponible</span> en tout temps.",
    "agile_efficient_available": "<b>Notre équipe est agile, efficace et toujours disponible.</b> Nous sommes rapides à répondre et nous nous efforçons de fournir un soutien constant et exceptionnel.",
    "passionate_about": "Êtes-vous <span>passionné</span> par les technologies web, le design graphique ou la création de contenu?",
    "curious_type": "Si vous êtes de nature curieuse, autodidacte et que vous visez la meilleure qualité, nous aimerions vous rencontrer!",
    "looking_for_talent": "Nous sommes constamment à la recherche de talents pour joindre notre équipe.",
    "close_knitted_team": "Nous sommes une équipe soudée et nous offrons une rémunération compétitive, des semaines de travail de 4 jours et des congés illimités.",
    "senior_web_developer": "Développeur web senior",
    "sales_specialist": "Directeur des ventes",
    "remote_full_time": "Télétravail - Temps plein",
    "junior_web_developer": "Développeur web junior",
    "remote_part_time": "Télétravail - Temps partiel",
    "graphic_designer": "Graphiste",
    "motion_designer": "Designer en animation",
    "french_only": "Français seulement",

}
