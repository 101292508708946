export const emails = {
    "title1": "Courriels automatisés",
    "text1": "Le marketing par courriel reste l'un des outils les plus puissants pour les organisations cherchant à atteindre leur public et à bâtir des relations significatives avec leurs clients.<br><br>Les courriels automatisés, également connus sous le nom de \"journeys\", sont une série de courriels automatisés envoyés à vos abonnés en fonction de leur comportement et de leurs actions.",
    "title2": "Nos solutions de courriels sont flexibles et vous permettent <span>d'ajuster votre stratégie</span> en fonction du feedback et des données reçues <span>en temps réel</span>.",
    "title3": "Notre équipe travaille avec vous à chaque étape pour garantir que <span>vos campagnes de courriels sont efficaces</span> et alignées avec votre stratégie numérique.",
    "bullet1": "<span class='big text-5xl bold block'>250+</span>campaignes courriels",
    "bullet2": "<span class='big text-5xl bold block'>75+</span>scénarios automatisés",
    "bullet3": "<span class='big text-5xl bold block'>15 ans</span>d'expérience",
    "bullet4": "<span class='big text-5xl bold block'>12</span>développeurs <br> et designers",
}
