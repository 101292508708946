export const interfaces = {
    "title1": "Admin interfaces",
    "text1": "We create user-friendly, secure, and scalable intranet platforms tailored to your specific requirements.<br/><br/>Our primary goal is to ensure that your employees have access to the right resources and tools to boost productivity and collaboration while enhancing workflow efficiency within your organization.",
    "title2": "We build secure and easy-to-navigate systems that streamline processes, <span>manage sensitive data</span>, and simplify communications between stakeholders.",
    "title3": "Let us help you <span>unlock the full potential</span> of your organization's collaboration capabilities.",
    "bullet1": "<span class='big text-5xl bold block'>300+</span>websites created",
    "bullet2": "<span class='big text-5xl bold block'>150+</span>happy clients",
    "bullet3": "<span class='big text-5xl bold block'>15 years</span>in business",
    "bullet4": "<span class='big text-5xl bold block'>12</span>developers <br> and designers",
}
