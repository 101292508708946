export const vrar = {
    "title1": "VR/AR Experiences",
    "text1": "Virtual reality (VR) and augmented reality (AR) have revolutionized the way we experience the world around us. <br><br>VR/AR experiences allow your business to create immersive and engaging environments that empower your customers to interact with your products or services in a bright new way.",
    "title2": "From 360-degree virtual tours to interactive product demos, <span>we use the latest technology and design techniques</span> to create immersive experiences for your customers.",
    "title3": "Our VR/AR experiences <span>can be deployed in a variety of settings</span>, including trade shows, conferences, retail stores, and online platforms.",
    "bullet1": "<span class='big text-5xl bold block'>5+</span>VR environments created",
    "bullet2": "<span class='big text-5xl bold block'>15+</span>AR campaigns created",
    "bullet3": "<span class='big text-5xl bold block'>15 years</span>in business",
    "bullet4": "<span class='big text-5xl bold block'>12</span>developers <br> and designers",
}
