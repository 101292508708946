export const cloudhosting = {
    "title1": "Cloud infrastructure (CI/CD)",
    "text1": "We work with a variety of cloud providers and platforms, including Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP), to deliver the most suitable solution for your organization.<br><br>Our commitment to security and compliance ensures that your data remains protected and adheres to industry standards and regulations.",
    "button": "Contact us",
    "title2": "Embracing cloud technologies allows your organization to access computing resources on-demand, <span>scale quickly to accommodate growth</span>, and significantly reduce IT costs.",
    "title3": "By choosing our cloud services, you'll be investing in a future-proof IT environment that not only <span>improves efficiency and agility</span> but also drives long-term growth and success.",
    "bullet1": "<span class='big text-5xl bold block'>SOC2</span>compliant",
    "bullet2": "<span class='big text-5xl bold block'>ISO 27001</span>compliant",
    "bullet3": "<span class='big text-5xl bold block'>PCI</span>compliant",
    "bullet4": "<span class='big text-5xl bold block'>15 years</span>in business",
}