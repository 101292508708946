export const about = {
    "title": "It all started 15 years ago in a small appartment in",
    "location": "Montreal",
    "header": "It has grown into a full team of <span>multidisciplinary</span> developers, designers and content creators.",
    "ceo": "CEO, Managing Director",
    "leadDeveloper": "Lead developer",
    "directorOperations": "Director, Operations",
    "directorSales": "Director, Sales",
    "projectManager": "Project manager",
    "webDeveloper": "Web developer",
    'teamLead': "Development lead",
    "seniorDeveloper": "Senior developer",
    "frontDeveloper": "Front-End Developer",
    "illustratorDesigner": "Illustrator & Designer",
    "graphicDesigner": "Graphic designer",
    "contentStrategist": "COO, partner",
    "translator": "Translator",
    "networkingIT": "Networking and IT",
    "whyWorkWithRollin": "Why choose us?",
    "whyText":"<b>We're a nimble team of expert developers.</b><br/><br/>We have the knowledge and experience required to tackle complex projects and deliver high-quality, tailor-made solutions for our clients.",
    "weAreCloseKnitted": "We are a close knitted team and we offer competitive compensation, 4 day workweeks and unlimited PTO.",
    "thinkGreatFit": "If you think you'd be a great fit, check-out our job openings.",
    "seeWhyDifferent": "See why we are different",
    "lookingForGreatPlace": "Looking for a great place to work?",
    "alwaysOnLookout": "We are always on the lookout for great talent!",
    "viewJobOpenings": "View our job openings",
    "lookingForResources": "Looking for technical resources?",
    "understandTechnicalChoices": "If you are looking to better understand the technical choices behind the technologies we use, consult our resource page to learn more about graphic design, CMS options and the web in general.",
    "checkResources": "Check out our resources",
    "highly_specialized": "We are a small team of <span>highly specialized</span> developers.",
    "nimble_team": "We're a nimble team of expert developers. We have the knowledge and experience required to tackle complex projects and deliver high-quality, tailor-made solutions for our clients.",
    "famous_brands": "We partner with <span>famous brands</span> to create unique experiences.",
    "worked_with_brands": "We combine our expertise with our partners' unique visions to produce memorable experiences that delight both our clients and their audiences. We've worked with some of the largest brands in the world like Diesel, Nike and Twitter.",
    "well_renowned_tools": "We use <span>well-renowned tools</span> that are built to ensure your success.",
    "harness_power": "We harnesses the power of time-tested and reputable technologies to deliver scalable, high-performance solutions that align with your specific requirements and objectives.",
    "quick_effective_available": "Our team is <span>quick, effective and available</span> at all times.",
    "agile_efficient_available": "<b>Our team is agile, efficient, and always available.</b> We are quick to respond and we strive to provide consistent and exceptional support.",
    "passionate_about": "Are you <span>passionate</span> about web technologies, graphic design or content creation?",
    "curious_type": "If you are the curious type, a self-learner and aim only for top-quality, we would like to meet to you!",
    "looking_for_talent": "We are constantly looking for great talent to join our team.",
    "close_knitted_team": "We are a close-knit team and we offer competitive compensation, 4 day workweeks and unlimited PTO.",
    "senior_web_developer": "Senior web developer",
    "sales_specialist": "Sales manager",
    "remote_full_time": "Remote - Full time",
    "junior_web_developer": "Junior web developer",
    "remote_part_time": "Remote - Part time",
    "graphic_designer": "Graphic designer",
    "motion_designer": "Motion designer",
    "french_only": "French only",
}
