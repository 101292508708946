export const customweb = {
    "slideTitle": "Custom web development",
    "slideText": "We offer premium solutions for businesses and organizations with very specific needs that surpass the limitations of conventional, one-size-fits-all platforms.<br/><br/>We understand that every business is unique, and to succeed in today's competitive digital landscape, you need a web presence that reflects your distinct brand identity and caters to your specific requirements.",
    "craftDigitalExperiences": "We craft digital experiences that not only align with your brand's values and aesthetic, but also provide a <span>seamless and efficient</span> user experience.",
    "enterpriseFrameworks": "We work exclusively with industry-leading tools and robust <span>enterprise-grade frameworks</span> to ensure exceptional performance and reliability.",
    "bullet1_number": "<span class='big text-5xl bold block'>300+</span>websites created",
    "bullet2_number": "<span class='big text-5xl bold block'>150+</span>happy clients",
    "bullet3_number": "<span class='big text-5xl bold block'>15 years</span>in business",
    "bullet4_number": "<span class='big text-5xl bold block'>12</span>developers <br> and designers",
    "bullet1": "<span class='big text-5xl bold block'>300+</span>websites created",
    "bullet2": "<span class='big text-5xl bold block'>150+</span>happy clients",
    "bullet3": "<span class='big text-5xl bold block'>15 years</span>in business",
    "bullet4": "<span class='big text-5xl bold block'>12</span>developers <br> and designers",
}
